@tailwind components;

@layer components {
  .menu-link {
    @apply block w-full p-3 hover:bg-gray-700;
  }

  .menu-link.active {
    @apply bg-gray-600;
  }

  .menu-link.parent {
    @apply block w-full p-3 hover:bg-gray-800;
  }

  .menu-link.child {
    @apply block w-full py-2 pl-6 pr-3;
  }

  .menu-link.child.active {
    @apply bg-gray-600;
  }

  .nav-container {
    @apply bg-gray-800 border-r border-gray-700 w-72 h-screen absolute top-0 left-0 z-50 flex-shrink-0 shadow-lg shadow-black;
    transition: all 200ms ease-in-out;
    transform: translateX(-100%);
  }

  .nav-container.open {
    transform: translate(0);
  }

  .maskLayer {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

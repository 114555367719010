@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  label {
    @apply font-bold text-sm;
  }

  input,
  textarea {
    @apply border border-gray-700 bg-gray-900 px-3 py-2 rounded w-full outline-none outline-offset-0 focus:outline focus:outline-blue-600 hover:border-blue-600 resize-none;
  }

  input.hasError,
  textarea.hasError {
    @apply border border-red-500 focus:outline-red-500 hover:border-red-500;
  }

  select {
    @apply border border-gray-700 bg-gray-900 px-3 py-2 rounded w-full outline-none outline-offset-0 focus:outline focus:outline-blue-600 hover:border-blue-600;
  }

  input[type="checkbox"],
  input[type="radio"] {
    @apply px-3 py-3 bg-gray-900 w-auto accent-blue-600;
  }

  .button {
    @apply bg-gray-100 hover:bg-white rounded text-gray-900 py-2 px-5 outline-none focus:outline active:outline active:outline-blue-600 focus:outline-blue-600;
    transition: all 300ms;
  }

  .button[disabled] {
    @apply bg-gray-400 hover:bg-gray-400 cursor-not-allowed;
  }

  .button:hover {
    transform: translateY(-0.2rem);
  }

  input[type="date"],
  input[type="time"] {
    color-scheme: dark;
  }

  input[disabled] {
    @apply text-gray-300 hover:border-gray-600 hover:cursor-not-allowed;
  }

  .datetimepicker {
    @apply bg-gray-900 w-full grid grid-cols-2 border border-gray-700 rounded;
  }

  .datetimepicker.disabled {
    @apply text-gray-300 hover:border-gray-600 hover:cursor-not-allowed;
  }

  .toggle-button-container {
    @apply rounded-full w-32 bg-gray-900 border border-gray-700 h-10 flex justify-between items-center px-4 relative;
  }

  .toggle-button-slider {
    @apply w-14 h-8 absolute z-20 rounded-full ml-1 border border-gray-500;
    transition: all 300ms;
  }

  .toggle-button-slider.off {
    @apply bg-gray-700 left-0;
    transform: translateX(0%);
  }

  .toggle-button-slider.on {
    @apply bg-blue-700 left-full border-blue-500;
    transform: translateX(-115%);
  }

  .three-toggle-button-container {
    @apply rounded-full w-[14.5rem] bg-gray-900 border border-gray-700 h-10 px-1 flex items-center;
  }

  .three-toggle-button-inner {
    @apply w-56 h-10 flex items-center relative;
  }

  .three-toggle-button-slider {
    @apply w-1/3 h-8 absolute z-20 rounded-full border border-gray-500 bg-gray-700;
    transition: all 300ms;
  }

  .three-toggle-button-slider.no {
    @apply left-0;
    transform: translateX(0%);
  }

  .three-toggle-button-slider.maybe {
    @apply left-1/2;
    transform: translateX(-50%);
  }

  .three-toggle-button-slider.yes {
    @apply left-full;
    transform: translateX(-100%);
  }

  .three-toggle-button-label {
    @apply flex w-1/3 text-sm z-30 items-center justify-center cursor-pointer;
  }

  .checkbox-container {
    @apply bg-gray-900 border-2 border-gray-700 h-8 w-8 mr-3 relative flex items-center justify-center rounded overflow-hidden;
  }

  .checkbox-container.disabled {
    @apply cursor-not-allowed;
  }

  .checkbox-checkmark {
    @apply bg-blue-700 h-5 w-5 left-0 top-0 rounded border border-blue-500;
    transition: all 300ms;
    background-position: center;
    transform: translate(-120%);
  }

  .checkbox-checkmark.checked {
    @apply bg-blue-700 border border-blue-500;
    background-image: url("./images/checkmark.svg");
    background-position: center;
    transform: translate(0%);
  }
}
